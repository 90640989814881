import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { useForm } from "react-hook-form";
import QRCode from "qrcode.react";
import * as yup from "yup";
import logo from "../assets/images/logo.png";
import { yupResolver } from "@hookform/resolvers/yup";
import Flatpickr from "react-flatpickr";
import { useDispatch, useSelector } from "react-redux";
import {
  ClickToshowEditProfile,
  deleteUser,
  getChild,
  updateUser,
} from "../redux/kids";
import { confirmAlert, errorDeleteAlert } from "../utility/alert";
import QrImg from "../assets/images/qr.svg";
import { Spinner, Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import { getUserData } from "../utility";

const ChildComponent = ({
  name,
  imageSrc,
  dob,
  id,
  qrCodeSrc,
  formatDate,

  saveAs,
  html2canvas,
  store2,
}) => {
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);
  const [picker, setPicker] = useState(dob);
  const [barcodeInputValue, updateBarcodeInputValue] = useState("");
  const [info, setInfo] = useState(false);
  const [res, setRes] = useState("");
  const [childLoading1, setChild] = useState(false);
  const userData = getUserData();

  const dataChild = useSelector((state) => state?.kids?.childInfo);
  const childLoading = useSelector((state) => state?.kids?.childLoading);

  console.log(userData.data.user_name);
  const downloadQR = () => {
    // Replace "your-canvas-id" with the actual ID of your canvas element
    const canvas = document.getElementById("my-qrcode");

    if (!canvas) {
      console.error("Canvas element not found.");
      return;
    }

    const pngUrl = canvas
      .toDataURL("image/png")
      .replace("image/png", "image/octet-stream");

    let downloadLink = document.createElement("a");
    downloadLink.href = pngUrl;
    downloadLink.download = `${name}` + " " + userData.data.user_name + ".png";

    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };
  const validationSchema = yup.object().shape({
    name: yup.string().required("اسم الطفل مطلوب"),
  });
  const handleModalOpen = () => {
    setShowModal(true);
  };

  const handleModalClose = () => {
    setShowModal(false);
    setChild(false);
    dispatch(ClickToshowEditProfile());

    reset({
      name,
      dob,
      qrCodeSrc,
    });
  };

  const handleDelete = (id) => {
    if (store2?.data?.length === 0) {
      dispatch(deleteUser(id));
      console.log("nn");
      reset({
        name,
        dob,
        qrCodeSrc,
      });
    }
    errorDeleteAlert();
    handleModalClose();
  };

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      name: name,
    },
  });

  const onSubmit = (data) => {
    const formData = new FormData();
    formData.append("fname", data?.name);
    formData.append("dob", picker);
    formData.append("_method", "put");
    dispatch(
      updateUser({
        id: id,
        user: formData,
      })
    );
    reset({
      name,
      dob,
      qrCodeSrc,
    });
    /*     handleModalClose();
     */
  };

  function barcodeAutoFocus() {
    document.getElementById("barcode")?.focus();
  }

  function onChangeBarcode(event) {
    updateBarcodeInputValue(event.target.value);
  }

  function onKeyDown(event) {
    if (event.keyCode === 13) {
      dispatch(getChild(barcodeInputValue));
      updateBarcodeInputValue("");
    }

    //   axios
    //     .get(`${mainUrl}/api/v1/web/kids/scanQR/${barcodeInputValue}`, {
    //       _method: "get",
    //     })
    //     .then((res) => {
    //       setRes(res);
    //       console.log(res);
    //       /*           successAlert()
    //        */ setInfo(!info);
    //       updateBarcodeInputValue("");
    //     })
    //     .catch((err) => {
    //       // if (err?.response?.data?.message) {
    //       //   errorAlert(err?.response?.data?.messages[0])
    //       // } else {
    //       //   errorAlert(err)
    //       // }
    //       updateBarcodeInputValue("");
    //     });
    // }
  }

  return (
    <div className="child" dir="rtl">
      {imageSrc !== null && imageSrc !== undefined ? (
        <div className="position-relative">
          <img src={imageSrc} alt={name} onClick={handleModalOpen} />
          <img src={QrImg} className="qr-image" onClick={handleModalOpen} />
        </div>
      ) : (
        <span
          className="position-relative badge rounded-pill text-capitalize mb-2"
          onClick={handleModalOpen}
        >
          {name?.substr(0, 1)}
          <img src={QrImg} className="qr-image" />
        </span>
      )}
      <div className="child-info">
        <h6 className="text-capitalize">{name}</h6>
      </div>
      <Modal show={showModal} onHide={handleModalClose}>
        <Modal.Header closeButton dir="rtl">
          <Modal.Title>معلومات الطفل</Modal.Title>
        </Modal.Header>
        <Modal.Body
          className="d-flex justify-content-center"
          style={{ height: "auto" }}
        >
          {childLoading === "idle" && childLoading1 === false ? (
            <>
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="row">
                  {/*  <div className="col-md-6 mb-3">
                    <label className="form-label">اسم الطفل</label>
                    <input
                      className="mt-2 form-control"
                      placeholder="ادخل اسم الطفل"
                      autoFocus={true}
                      type="text"
                      {...register("name")}
                      id="name"
                      // onClick={() => {
                      //   document.getElementById("name")?.focus();
                      // }}
                    />
                    {errors.name && (
                      <small className="text-danger">
                        {errors.name.message}
                      </small>
                    )}
                  </div>
                  <div className="col-md-6 mb-3">
                    <label className="form-label"> تاريخ ميلاد الطفل</label>
                    <Flatpickr
                      className="mt-2 form-control"
                      placeholder="ادخل  تاريخ ميلاد الطفل"
                      options={{
                        dateFormat: "Y-m-d",
                        maxDate: new Date(),
                      }} // Specify the desired date format
                      value={picker}
                      onChange={(e) => setPicker(formatDate(e))}
                    />
                  </div> */}
                </div>
                <p className="mt-1 d-flex justify-content-center mb-1">
                  مرحبا بك في مدينة المرح
                </p>
                <QRCode
                  className="d-flex justify-content-center mb-1"
                  size={256}
                  style={{ height: "auto", maxWidth: "100%" }}
                  value={qrCodeSrc}
                  id="my-qrcode"
                  viewBox={`0 0 256 256`}
                />
                <Link
                  className="d-flex justify-content-center mt-1"
                  onClick={downloadQR}
                >
                  {" "}
                  Download QR{" "}
                </Link>
                <p className="d-flex justify-content-center mt-1">{name}</p>

                {/*  <input
                  type="text"
                  autoFocus={true}
                  name="barcode"
                  value={barcodeInputValue}
                  onChange={onChangeBarcode}
                  style={{
                    position: "relative",
                    width: "1px",
                    opacity: "0",
                  }}
                  id="barcode"
                  onKeyDown={onKeyDown}
                  onBlur={barcodeAutoFocus}
                ></input> */}
                {/* <div className="buttons mt-4">
                  <button
                    className="btn btn-primary btn-main ms-2"
                    type="submit"
                  >
                    تعديل
                  </button>
                  <button
                    className="btn btn-danger"
                    onClick={(e) => {
                      e.preventDefault();
                      confirmAlert(id, handleDelete);
                    }}
                  >
                    حذف
                  </button>
                </div> */}
              </form>
            </>
          ) : (
            <>
              {childLoading === "work" ? (
                <>
                  <Table>
                    <thead className="d-flex flex-column justifiy-content-spacebetween">
                      <tr>
                        <th scope="col">Updated_at</th>
                        <th scope="col">Created_at</th>
                        <th scope="col">Date_of_birthday</th>
                        <th scope="col">Child_Name</th>
                      </tr>
                    </thead>
                    <tr className="d-flex flex-row justify-content-around">
                      <td scope="col">{dataChild?.updated_at.slice(1, 5)}</td>
                      <td scope="col">{dataChild?.created_at.slice(1, 5)}</td>
                      <td scope="col">{dataChild?.dob.slice(1, 5)}</td>
                      <td scope="col">{dataChild?.fname}</td>
                    </tr>
                  </Table>{" "}
                  {/*              <table class="table">
                    <thead>
                      <tr>
                        <th scope="col">#</th>
                        <th scope="col">First</th>
                        <th scope="col">Last</th>
                        <th scope="col">Handle</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <th scope="row">1</th>
                        <td>Mark</td>
                        <td>Otto</td>
                        <td>@mdo</td>
                      </tr>
                      <tr>
                        <th scope="row">2</th>
                        <td>Jacob</td>
                        <td>Thornton</td>
                        <td>@fat</td>
                      </tr>
                      <tr>
                        <th scope="row">3</th>
                        <td>Larry</td>
                        <td>the Bird</td>
                        <td>@twitter</td>
                      </tr>
                    </tbody>
                  </table> */}
                </>
              ) : (
                <Spinner />
              )}
            </>
          )}
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default ChildComponent;
