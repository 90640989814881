import { yupResolver } from "@hookform/resolvers/yup";
import axios from "axios";
import cogoToast from "cogo-toast";
import "flatpickr/dist/themes/material_blue.css";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import * as yup from "yup";
import Logo from "../assets/images/logo.png";
import InputPasswordToggle from "../components/Input";
// import { setpassword } from "../redux/code";

const Reset = () => {
  const { state } = useLocation();
  console.log("🚀 ~ file: ResetPasswordByPhone.js:16 ~ Reset ~ state:", state);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const validationSchema = yup.object().shape({
    password: yup
      .string()
      .min(8, "كلمة المرور يجب ان تكون مكونة من 8 محارف")
      .required("كلمة المرور مطلوبة"),
    c_password: yup
      .string()
      .oneOf([yup.ref("password")], "كلمة المرو   يجب ان تكون متطابقة")
      .required("تاكيد كلمة المرور مطلوب"),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      password: "",
      c_password: "",
    },
  });

  const onSubmit = (data) => {
    setLoading(true);
    const formData = new FormData();
    formData.append("new_password", data.password);
    formData.append("c_password", data.c_password);
    formData.append("phone", state?.phone);
    axios
      .post("https://api.joycity.club/api/v1/web/auth/reset-password", formData)
      .then((response) => {
        // dispatch(setpassword(data.password));
        navigate("/login");
      })
      .catch((err) => {
        console.log(err);
        cogoToast.error(err?.response?.data?.messages[0], {
          position: "bottom-left",
        });
        setLoading(false);
      });
  };
  return (
    <>
      <div className="top-section pt-5 pb-5">
        <div className="container">
          <div className="row">
            <div className="col-md-12 text-center">
              <img src={Logo} width={"300px"} />
              <h1 className="mt-4">مرحبا بك في مدينة المرح</h1>
              <div className="bg-white form-wrap mt-5 p-4">
                <form
                  className="text-end"
                  dir="rtl"
                  onSubmit={handleSubmit(onSubmit)}
                >
                  <div className="row">
                    <div className="col-md-6 mb-3">
                      <label className="form-label">
                        {" "}
                        كلمة المرور الجديدة{" "}
                      </label>
                      <InputPasswordToggle
                        className="mt-2 form-control"
                        placeholder=""
                        {...register("password")}
                      />
                      {/* <input
                        className="mt-2 form-control"
                        placeholder="ادخل    كلمة المرور"
                        type="password"
                        {...register("password")}
                      /> */}
                      {errors.password && (
                        <small className="text-danger">
                          {errors.password.message}
                        </small>
                      )}
                    </div>
                    <div className="col-md-6 mb-3">
                      <label className="form-label">تاكيد كلمة المرور </label>
                      <InputPasswordToggle
                        className="mt-2 form-control"
                        placeholder=""
                        {...register("c_password")}
                      />
                      {/* <input
                        className="mt-2 form-control"
                        placeholder="ادخل   تاكيد كلمة المرور"
                        type="password"
                        {...register("c_password")}
                      /> */}
                      {errors.c_password && (
                        <small className="text-danger">
                          {errors.c_password.message}
                        </small>
                      )}
                    </div>
                    <button className="register-btn mt-4" type="submit">
                      {loading ? "يتم التغيير.." : "تغيير كلمة المرور"}
                    </button>
                    <Link to="/login" className="mt-3 text-center">
                      {" "}
                      <b>العودة الى تسجيل الدخول</b>
                    </Link>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Reset;
